import React from 'react'
import { Container, Row, Col } from 'react-grid-system'
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl'

import Layout from '../../components/Layouts/layout'
import SEO from '../../components/seo'
import ComplaintsProcessNav from '../../components/SubPageNav/ComplaintsProcessNav'
import SubPageBottomNav from '../../components/SubPageBottomNav/SubPageBottomNav'

const ComplaintsProcessPage = () => {
  const intl = useIntl()
  return (
    <Layout header={<FormattedMessage id='complaintsProcess.page01' />}>
      <SEO
        description={intl.formatMessage({
          id: 'metaTags.complaintsProcessDescription'
        })}
        lang={intl.locale}
        title={intl.formatMessage({ id: 'complaintsProcess.page01' })}
      />
      <Container>
        <Row>
          <Col lg={3} md={3}>
            <ComplaintsProcessNav activePath='/licensing-compliance/complaints-process/' />
          </Col>

          <Col>
            <Row>
              <Col>
                <h2>
                  <FormattedMessage id='complaintsProcess.header01' />
                </h2>
                <p>
                  <FormattedMessage id='complaintsProcess.content01A' />
                </p>

                <p>
                  <FormattedMessage id='complaintsProcess.content01B' />
                </p>
                <ol className='largeNums'>
                  <li>
                    <div>
                      <b>
                        <FormattedMessage id='complaintsProcess.list01A_B' />
                      </b>
                      <br />
                      <FormattedMessage id='complaintsProcess.list01A' />
                    </div>
                  </li>
                  <li>
                    <div>
                      <b>
                        <FormattedMessage id='complaintsProcess.list01B_B' />
                      </b>
                      <br />
                      <FormattedMessage id='complaintsProcess.list01B' />
                    </div>
                  </li>
                  <li>
                    <div>
                      <b>
                        <FormattedMessage id='complaintsProcess.list01C_B' />
                      </b>
                      <br />
                      <FormattedMessage id='complaintsProcess.list01C' />
                    </div>
                  </li>
                  <li>
                    <div>
                      <b>
                        <FormattedMessage id='complaintsProcess.list01D_B' />
                      </b>
                      <br />
                      <FormattedMessage id='complaintsProcess.list01D' />
                    </div>
                  </li>
                </ol>

                <p>
                  <FormattedMessage id='complaintsProcess.content01C' />
                </p>

                <p>
                  <FormattedMessage id='complaintsProcess.content01D' />
                </p>

                <p>
                  <FormattedMessage id='complaintsProcess.content01E' />
                </p>

                <ul>
                  <li>
                    <FormattedMessage id='complaintsProcess.list06A' />
                  </li>
                  <li>
                    <FormattedMessage id='complaintsProcess.list06B' />
                  </li>
                  <li>
                    <FormattedMessage id='complaintsProcess.list06C' />
                  </li>
                  <li>
                    <FormattedMessage id='complaintsProcess.list06D' />
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col>
            <SubPageBottomNav
              nextLink={{
                text: <FormattedMessage id='complaintsProcess.page02' />,
                path: '/licensing-compliance/how-to-file-a-complaint/'
              }}
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default ComplaintsProcessPage
